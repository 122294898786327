.portalWrapper {
  margin-inline: 0em;
  margin-block: 2em;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
}

.portalWrapper .content {
  display: grid;
  position: relative;
  padding: 1em;
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  align-items: center;
  gap: 2em;
  overflow: hidden;
  overflow-y: scroll;
}

.portalWrapper .header {
  display: flex;
  justify-content: space-between;
  padding-inline: 16px;
  margin-bottom: 2em;
}

.portalWrapper .buttons {
  display: flex;
  gap: 1em;
}

@media (min-width: 560px) {
  .portalWrapper {
    margin-inline: 0.5em;
  }
}
@media (min-width: 700px) {
  .portalWrapper {
    margin-inline: 1em;
    text-align: center;
  }
  .portalWrapper .content {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 940px) {
  .portalWrapper {
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .portalWrapper .content {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 1600px) {
  .portalWrapper .content {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.portalWrapper > h1 {
  margin-top: 0;
}

.addedItem {
  height: 100%;
  text-align: center;
  width: 100%;
  display: grid;
  grid-template-columns: 100px auto;
  grid-template-rows: auto 50px;
  gap: 0.5em;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 1px 0.5em 0px;
  padding: 0.5em;
  border-radius: 5px;
}

.addedItem > * {
  grid-column: auto/span 1;
}

.addedItem h2 {
  grid-column: auto/span 2;
  margin: 0;
}

.addedItem input {
  grid-row: 2;
  height: 100%;
}

.addedItem button {
  grid-row: 2;
  height: 100%;
}

.portalWrapper > button {
  top: 2em;
  right: 2em;
  position: absolute;
}
