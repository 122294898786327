.loginWrapper {
  display: flex;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginWrapper .LoginForm {
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 1px 0.5em 0px;
  border-radius: 15px;
  background-color: white;
  padding-inline: 3em;
  padding-block: 6em;
  gap: 2em;
}

.loginWrapper .LoginButton {
  height: 4em;
}

.loginWrapper .LoginHeader {
  text-align: center;
  font-size: 2em;
  margin-bottom: 21;
}

.loginWrapper .LoginInfo {
  color: #999;
  font-weight: 600;
  font-size: 14px;
}

.loginWrapper .MSG {
  font-weight: 600;
  position: absolute;
  bottom: 3em;
  width: 100%;
  left: 0;
  text-align: center;
  word-wrap: break-word;
}

.webhook {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 2em;
  left: 2em;
  opacity: 0.2;
  gap: 3px;
}

.webhook > * {
  padding: 0;
  margin: 0;
  font-size: 10px;
}

.webhook svg {
  width: 12px;
  height: 12px;
}
