.searchWrapper {
  --addedColor-b: #1976d2;
  --addedColor-g: #c3ecc6;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content auto;
  height: 100vh;
  width: 150px;
  padding-inline: 1em;
  padding-block: 2em;
  box-shadow: var(--d-boxshadow);
  flex-direction: column;
}

.searchGrid {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.searchContent {
  overflow-y: scroll;
  box-shadow: inset var(--d-boxshadow);
  background-color: #fff;
  border-radius: 5px;
  padding: 1em;
}

.searchItem {
  display: grid;
  grid-template-columns: min-content;
  grid-template-rows: auto auto auto;
  text-align: center;
  margin-bottom: 1em;
  padding: 0.2em;
  background: white;
  box-shadow: var(--d-boxshadow);
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: 200ms ease-in-out;
}

.searchItem.added {
  background: var(--addedColor-b);
  color: white;
}

.searchItem > * {
  margin: 0;
  padding: 0;
}

.searchItem h1 {
  grid-column: 1 / span 1;
  grid-row: 1;
  font-size: 1em;
  margin-bottom: 1em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.searchItem .infobox {
  grid-column: 1 / span 1;
  grid-row: 2;
  font-size: 10px;
}

.searchItem .infobox > * {
  padding: 0;
  margin: 0;
}

.searchItem button {
  grid-row: auto / span 2;
  grid-column: 2 / span 1;
  width: 40px;
  height: 40px;
  place-self: center center;
}

.searchItem.added button {
  background: white;
  box-shadow: var(--d-boxshadow);
}

@media (min-width: 500px) {
  .searchWrapper {
    width: 200px;
  }
  .searchItem {
    grid-template-columns: auto min-content;
    grid-template-rows: auto auto;
    text-align: left;
    justify-content: initial;
    padding: 0.5em;
  }
  .searchItem button {
    grid-column: 2;
  }
}
@media (min-width: 780px) {
  .searchWrapper {
    width: 250px;
  }
}
@media (min-width: 940px) {
  .searchWrapper {
    width: 300px;
  }
}
@media (min-width: 1200px) {
  .searchWrapper {
    width: 400px;
  }
}
