.loading-wrapper {
  display: flex;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-wrapper .info-text {
  position: absolute;
  transform: translateY(300%);
  color: #ddd;
}

.webhook {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 2em;
  left: 2em;
  opacity: 0.2;
  gap: 3px;
}

.webhook > * {
  padding: 0;
  margin: 0;
  font-size: 10px;
}

.webhook svg {
  width: 12px;
  height: 12px;
}
