.App {
  --box-shadow: rgba(100, 100, 111, 0.2) 0px 1px 0.5em 0px;
  position: relative;
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-rows: 100vh;
  overflow-y: hidden;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
